import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout/Layout";
import { ServiceData } from "../components/sections/index/OurServices";
import { WorkData, OurWork } from "../components/sections/index/OurWork";
import { OurServices } from "../components/sections/index/OurServices";
import { Navigation } from "../components/navigation/Navigation";
import { HeaderSize, ParallaxHeader } from "../components/layout/ParallaxHeader";
import { HeaderInfo } from "../components/layout/HeaderInfo";
import { Hiring } from "../components/sections/index/Hiring";
import { indexPageData } from "../content/pageData/IndexData";
import { RunebornLogo } from "../components/sections/index/RunebornLogo";

type PageData = {
    data: {
        site: {
            siteMetadata: {
                title: string;
                description: string;
            }
        }
        runebornHeader: {
            childImageSharp: any;
        }
        runebornHeaderMobile: {
            childImageSharp: any;
        }
        runebornCard: {
            childImageSharp: any;
        }
        rrCard: {
            childImageSharp: any;
        }
        nana: {
            childImageSharp: any;
        }
        allMarkdownRemark: {
            edges: [{
                node: {
                    fields: {
                        slug: string
                    }
                    frontmatter: {
                        title: string
                    }
                }
            }]
        }
    }
}

export default ({ data }: PageData) => {
    const title = indexPageData.title;
    const nana = data.nana.childImageSharp.fluid;
    const runebornHeader = data.runebornHeader.childImageSharp.fluid;
    const runebornHeaderMobile = data.runebornHeaderMobile.childImageSharp.fluid;

    const runebornSources = [
        runebornHeaderMobile,
        {
            ...runebornHeader,
            media: `(min-width: 768px)`,
        }
    ]

    const workData: WorkData = { work: indexPageData.workData.work };

    for (const item of workData.work) {
        item.image.src = data[item.image.name].childImageSharp.fluid;
    }

    const serviceData: ServiceData = { title: indexPageData.serviceData.title, services: indexPageData.serviceData.services }
    serviceData.services[0].featuredImage = nana;
    serviceData.services[1].featuredImage = nana;

    return (
        <Layout description={data.site.siteMetadata.description} keywords={indexPageData.keywords} pageTitle={indexPageData.pageTitle}>
            <Navigation />
            <ParallaxHeader size={HeaderSize.LARGE} backgroundImage={runebornSources} className="dark-black-background">
                <HeaderInfo logoElement={<RunebornLogo />}
                    ctaLabel={"Learn More"}
                    ctaLink={"https://runeborn.com/"}
                    externalLink={true}
                    ctaAriaLabel={"Learn more about Runeborn"}
                    cta2Label={"Press Release"}
                    cta2Link={"https://runeborn.com/press-release/feb2025"}
                    externalLink2={true}
                    cta2AriaLabel={"Runeborn Press Release"}
                />
            </ParallaxHeader>
            {/* <HeaderImageContainer>
                <Img className="orange-dragon" fluid={orangeDragonSources} alt={`Orange Viking Dragon`} />
                <Img className="green-dragon" fluid={greenDragon} alt={`Green Dragon`} />
            </HeaderImageContainer> */}
            <OurWork workData={{ title: indexPageData.workData.title, buttonLabel: indexPageData.workData.buttonLabel, work: workData.work }} />
            <OurServices serviceData={serviceData} />
            <Hiring />
        </Layout>
    );
};

export const query = graphql`
    query {
        site {
            siteMetadata {
                title
                description
            }
        }
        runebornHeader: file(relativePath: { eq: "assets/images/index/runeborn_header.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        runebornHeaderMobile: file(relativePath: { eq: "assets/images/index/runeborn_header_mobile.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 768, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        rrCard: file(relativePath: { eq: "assets/images/work/rr1_card.png" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        runebornCard: file(
            relativePath: { eq: "assets/images/work/runeborn_card.png" }
        ) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    
        nana: file(relativePath: { eq: "assets/images/index/nana.png" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`